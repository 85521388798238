<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <route-back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-xl-12">
          <h1>Add Insurance Coordinators</h1>
          <validation-observer tag="form" ref="observer" v-slot="{ invalid }" @submit.prevent="onSubmit">
            <card type="default" header-classes="bg-transparent" class="mt-3">
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    :rules="{required: true}"
                    name="name"
                    label="Template Name"
                    placeholder="Template Name"
                    v-model="insuranceCoordinator.name"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">Status*</label>
                  <base-input name="status" :rules="{required: true}">
                    <el-select
                      prop="status"
                      class="w-100 select-primary pagination-select"
                      v-model="insuranceCoordinator.status"
                      :rules="{required: true}"
                      name="status"
                      placeholder="Status"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in dropdowns.status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <html-editor @handleKeyPressForEditor="handleKeyPressForEditor"></html-editor>
<!--                  <base-input-->
<!--                    name="content"-->
<!--                    label="Content"-->
<!--                    v-model="insuranceCoordinator.content"-->
<!--                  />-->
                </div>
              </div>
            </card>
            <div class="mt-4">
              <base-button class="btn-primary-action" native-type="submit" type="submit" >Save</base-button>
            </div>
          </validation-observer>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/Router/BackButton";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  name: "AddInsuranceCoordinator",
  components: {
    RouteBackButton: BackButton,HtmlEditor
  },
  data() {
    return {
      loaders: {},
      insuranceCoordinator: {
        name: '',
        status: '',
        content: '',
      },
      dropdowns: {
        status: [
          {
            value: 0,
            label: 'Inactive'
          },{
            value: 1,
            label: 'Active'
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    clearForm() {
      this.insuranceCoordinator = {
        name: '',
        status: '',
        content: '',
      };
    },
    handleKeyPressForEditor(value) {
      // alert(value)
      this.insuranceCoordinator.content = value;
    },
    async onSubmit() {
      let th = this;
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        th.$notify.error({
          title: 'Error',
          message: 'Check your input'
        });
      }
      else{

        axios.post(this.$store.getters.getBaseUrl + '/api/insurance-coordinators', this.insuranceCoordinator).then((response) => {
          th.$notify.success({
            title: 'Success',
            message: 'Insurance Coordinator Added'
          });
          //th.clearForm();
          th.$router.back();
        }).catch(error => {
          let message = (error.response) ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          th.$notify.error({
            title: 'Checklist Template',
            message: message
          });
        }).finally(() => {

        });
      }

    },

  },
}
</script>

<style scoped>

</style>
